<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <alert ref="Alert"></alert>
    <datatable-heading
      :title="$t('menu.request-for-proposals')"
      :changePageSize="changePageSize"
      :searchChange="searchChange"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage">
      <template v-slot:button>
        <b-button
          v-b-modal.modalright
          variant="primary"
          size="lg"
          :to="{ name: 'addRequestForProposal' }">Add New</b-button>
      </template>
    </datatable-heading>

    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="0.85">
      <b-row>
        <b-col sm="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="requestForProposalID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:load-error="handleLoadError"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded">
              <template slot="active" slot-scope="props">
                <div style="display:flex;justify-content:center;align-items;center;">
                  <ToggleSwitch
                    v-on:input="deactivateRFP(props.rowData)"
                    v-model="props.rowData.active"
                    :emitOnMount="false"
                    theme="custom"
                    class="vue-switcher-small"
                    color="primary"
                    :id="props.rowData.requestForProposalID"
                    v-b-tooltip.hover.left :title="`Deactivate ${props.rowData.requestForProposalID}`"/>
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage" />
        </b-col>
      </b-row>
    </b-overlay>

    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit')">
        <i class="simple-icon-docs" />
        <span>Edit</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('deactivate')">
        <i class="simple-icon-trash" />
        <span>Deactivate</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import confirmation from '../../../../components/Common/Confirmation.vue'
import alert from '../../../../components/Common/Alert.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import switches from 'vue-switches'
import { BeaconapiUrl } from '../../../../constants/config'

import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import RequestForProposalMixin from '../../../../mixins/RequestForProposalMixin.vue'

import { mapMutations } from 'vuex'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    Alert: alert,
    ToggleSwitch: switches
  },
  mixins: [RequestForProposalMixin],
  data () {
    var _idfield = 'requestForProposalID'
    return {
      isLoad: false,
      apiBase: BeaconapiUrl + '/api/request-for-proposal',
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,

      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%'
        },
        {
          name: 'requestForProposalStatus',
          sortField: 'requestForProposalStatus',
          title: 'Status',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'companyLegalName',
          sortField: 'companyLegalName',
          title: 'Company Legal Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%'
        },
        {
          name: 'location',
          sortField: 'location',
          title: 'Location',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'dueDate',
          sortField: 'dueDate',
          title: 'Due Date',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'revenueEstimate',
          sortField: 'revenueEstimate',
          title: 'Revenue Estimate',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'staffMemberAssigned',
          sortField: 'staffMemberAssigned',
          title: 'Staff Member Assigned',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'createdBy',
          sortField: 'createdBy',
          title: 'Created By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: '__slot:active',
          sortField: 'active',
          title: 'Active',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned text-center',
          width: '5%'
        }
      ]
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    async onContextMenuAction (action) {
      switch (action) {
        case 'edit':
        {
          this.$router.push({ name: 'EditRequestForProposal', params: { requestForProposalID: this.rightClickedItem.requestForProposalID } })

          break
        }
        case 'deactivate':
        {
          await this.deactivateRFP(this.rightClickedItem)

          break
        }
        default:
          break
      }
    },

    // End of Component-specific Vue Table methods

    // Component-specific methods

    async deactivateRFP (requestForProposal) {
      this.$refs.Confirmation.show({
        title: 'Deactivate RFP',
        message: `Are you sure you want to deactivate RFP ID ${requestForProposal.requestForProposalID}?`,
        okButton: 'Yes',
        exitFunction: (e) => {
          if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
            this.refreshVueTable()
          }
        }
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage('')

          var deactivateRFPsList = [{ ID: requestForProposal.requestForProposalID, Active: false }]
          await this.activateRequestForProposals(deactivateRFPsList)
            .then(() => {
              this.refreshVueTable()
            })
        } else {
          this.refreshVueTable()
        }
      })
    }

    // End of Component-specific methods
  }
}
</script>
